body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

.scrolling {
  width: auto;
  height: auto;
}

.form-inner {
  padding: 20px;
}

.error {
  background-color: crimson;
  width: 150px;
}

.form-group {
  margin: 5px;
  width: 255px;
  display: flex;
  justify-content: space-between;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.tab-bar {
  background-color: aqua;
  text-align: center;
  margin: 10px;
  font-size: 32px;
}

.main_container {
  background-color: rgb(141, 194, 230);
  text-align: center;
  display: flex;
  justify-content: space-between;
  height: 400px;
  margin: 10px;
  font-size: 32px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.tool-content-field {
  width: 95%;
  height: 95%;
  text-align: center;
}

.tool-content-field-input {
  width: 95%;
  height: 95%;
  text-align: center;
}

.tool-frame {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
  padding: 10px;
  margin: 25px;
  width: 400px;
  height: 120px;
  box-shadow: 10px 10px;
  background-color: rgb(33, 136, 219);
}

.tool-button {
  width: 100px;
  height: 25px;
}
.webcam-title {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: larger;
}
.webcam {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  background-color: rgb(29, 170, 64);
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  margin-right: auto;
  margin-left: auto;

  padding: 5px;
  width: 650px;
}

.list-item, .list-item-mobile {
  /*box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
  transition: 0.3s;
  
  border-radius: 5px;
  border-color: rgb(199, 190, 165);
  padding: 2px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: rgb(70, 58, 45);
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 24px;
  align-items: right;
  width: 100%; /* Ensure ListItem takes the full width of its parent */
}

.action-buttons {
  display: flex;
  gap: 10px;  /* Spacing between buttons */
  align-items: right;
}

.button-43-yellow, .button-43-red {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.button-43-yellow img, .button-43-red img {
  width: 25px; /* Set image size */
}

.nav-main {

  display: flex;
  justify-content: center;


  height: 75px;

  margin: 10px;
  padding: 10px;
  justify-content: space-between;
}

.link-nav-container {
  display: flex;
  justify-content: center;

  column-gap: 50px;
}

.link-login-container {
  display: flex;
  justify-content: center;

  column-gap: 50px;
}

.link-nav-item {
  box-shadow: 0 10px 8px 0 rgba(51, 116, 255, 0.2);
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  padding: 5px;
  
  text-decoration: none;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 28px;
}

.link-nav-login-in {
  box-shadow: 0 10px 8px 0 rgba(51, 116, 255, 0.2);
  background-color: rgb(142, 241, 150);
  color: rgb(5, 61, 0);
  border-radius: 5px;
  padding: 5px;

  text-decoration: none;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 28px;
}

.link-nav-login-out {
  box-shadow: 0 10px 8px 0 rgba(51, 116, 255, 0.2);
  background-color: rgb(226, 131, 131);
  color: rgb(255, 255, 255);
  border-radius: 5px;
  padding: 5px;

  text-decoration: none;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 28px;
}

.shopping-container {
  width: 100%;  /* Make sure the container takes full width */
  height: 300px;
  display: flex;
  align-items: stretch;  /* Stretch the items across the full width */
}

.shopping-input {
  font-size: 18px;
  padding: 5px;
  border-radius: 5px;
  display: flex;

}


.klingel-confirmed {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border: 1px solid;
  border-radius: 5px;
  border-color: rgb(165, 199, 168);
  margin: 20px;
  background-color: rgb(160, 255, 157);

  display: flex;
  justify-content: space-between;

  color: rgb(70, 58, 45);
  text-decoration: none;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 40px;
}

.klingel-denied {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border: 1px solid;
  border-radius: 5px;
  border-color: rgb(199, 165, 165);
  margin: 20px;
  background-color: rgb(243, 111, 111);

  display: flex;
  justify-content: space-between;

  color: rgb(70, 58, 45);
  text-decoration: none;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 40px;
}

.klingel-received {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border: 1px solid;
  border-radius: 5px;
  border-color: rgb(199, 198, 165);
  margin: 20px;
  background-color: rgb(255, 253, 157);

  display: flex;
  justify-content: space-between;

  color: rgb(70, 58, 45);
  text-decoration: none;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 40px;
}

.klingel-later {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border: 1px solid;
  border-radius: 5px;
  border-color: rgb(199, 184, 165);
  margin: 20px;
  background-color: rgb(255, 213, 157);

  display: flex;
  justify-content: space-between;

  color: rgb(70, 58, 45);
  text-decoration: none;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 40px;
}

.klingel-none {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border: 1px solid;
  border-radius: 5px;
  border-color: rgb(197, 197, 197);
  margin: 20px;
  background-color: rgb(134, 134, 134);

  display: flex;
  justify-content: space-between;

  color: rgb(70, 58, 45);
  text-decoration: none;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 40px;
}



header {
  color: #212529;
}

.nav-area {
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px 20px;
}

.logo {
  text-decoration: none;
  font-size: 25px;
  color: inherit;
  margin-right: 20px;
}

.menus {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  padding:  10px;
}

.menu-items {
  position: relative;
  font-size: 14px;
}

.menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
}

.menu-items button {
  display: flex;
  align-items: center;
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
  height: 64px;
}

button span {
  margin-left: 3px;
}

.menu-items > a, .menu-items button {
  text-align: left;
  padding: 0.7rem 1rem;
}

.menu-items a:hover,
.menu-items button:hover {
  background-color: #f2f2f2;
}

.arrow::after {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
}

.dropdown {
  position: absolute;
  right: 0;
  left: auto;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: 0.875rem;
  z-index: 9999;
  min-width: 10rem;
  padding: 0.5rem 0;
  list-style: none;
  background-color: #fff;
  border-radius: 0.5rem;
  display: none;
}

.dropdown.show {
  display: block;
}

.dropdown .dropdown-submenu {
  position: absolute;
  left: 100%;
  top: -7px;
}

/* content */

.content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 3rem 20px;
}

.content h2 {
  margin-bottom: 1rem;
}

.content a {
  color: #cc3852;
  margin-right: 10px;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.shopping-page {
  margin: 30px;
  display: flex;
  flex-direction: column;
  row-gap: 90px;
}

.shopping-container {
  display: flex;
  gap: 10px;
  height: 50px;
}

.shopping-input {
  margin-right: 5px;
  font-size: 18px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 80%;
}

.add-item-btn {
  width: 80px;
  height: 50px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 24px;
  
}

.loading-image {
  width: 200px;
  display: block;
  margin: 0 auto;
}

.droppable-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.draggable-item {
  
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  padding-right: 30px;
  background-color: #fff56b;
  border-radius: 5px;
  box-shadow: 0 14px 8px rgba(0, 0, 0, 0.2);
  font-size: 18px;
}

.delete-all-container {
  margin-top: 50px;
  text-align: center;
}

.delete-all-btn {
  width: 64px;
  height: 64px;
  background: transparent;
  border: none;
  cursor: pointer;
}

.delete-confirm-btn {
  background-color: #F44336;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 18px;
}

.delete-all-btn img {
  width: 100%;
}

@media screen and (max-width: 600px) {
  .list-item {
    font-size: 24px;
    gap: 10px;
    word-wrap: break-word;
  }

  .list-item-mobile {
    font-size: 14px;
    height: 50px;
  }
  .shopping-container {
    flex-direction: column;
    align-items: center;
  }

  .shopping-input {
    width: 100%;
  }

  .add-item-btn {
    width: 100%;
  }

  .draggable-item {
    font-size: 16px;
  }
}
