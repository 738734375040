

/* CSS */
.button-43 {
  background-image: linear-gradient(-180deg, #37AEE2 0%, #1E96C8 100%);
  border-radius: .5rem;
  box-sizing: border-box;
  color: #FFFFFF;
  display: flex;
  font-size: 16px;
  justify-content: center;
  padding: 0.5rem 1.2rem;
  text-decoration: none;
  width: 100%;
  border: 0;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-43:hover {
  background-image: linear-gradient(-180deg, #1D95C9 0%, #17759C 100%);
}

@media (min-width: 768px) {
  .button-43 {
    padding: 1rem 2rem;
  }
}

.button-43-red {
  background-image: linear-gradient(-180deg, #e23737 0%, #c81e1e 100%);
  border-radius: .5rem;
  box-sizing: border-box;
  color: #FFFFFF;
  display: flex;
  font-size: 32px;
  justify-content: center;
  padding: 1rem 1.75rem;
  text-decoration: none;
  width: 100%;
  border: 0;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-43-red:hover {
  background-image: linear-gradient(-180deg, #c91d1d 0%, #9c1717 100%);
}

@media (min-width: 768px) {
  .button-43-red {
    padding: 1rem 2rem;
  }
}

.button-43-yellow {
  background-image: linear-gradient(-180deg, #f3ee56 0%, #99821d 100%);
  border-radius: .5rem;
  box-sizing: border-box;
  color: #ffffff;
  display: flex;
  font-size: 16px;
  justify-content: center;
  padding: 1rem 1.75rem;
  text-decoration: none;
  width: 100%;
  border: 0;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-43-yellow:hover {
  background-image: linear-gradient(-180deg, #f5dc4e 0%, #d8be2c 100%);
}

@media (min-width: 768px) {
  .button-43-yellow {
    padding: 1rem 2rem;
  }
}

.button-43-yellow-mobile {
  background-image: linear-gradient(-180deg, #f3ee56 0%, #99821d 100%);
  border-radius: .5rem;
  box-sizing: border-box;
  color: #ffffff;
  display: flex;
  font-size: 16px;
  justify-content: center;
  padding: 1rem 1.75rem;
  text-decoration: none;
  width: 100%;
  border: 0;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-43-yellow-mobile:hover {
  background-image: linear-gradient(-180deg, #f5dc4e 0%, #d8be2c 100%);
}

@media (min-width: 768px) {
  .button-43-yellow-mobile {
    padding: 1rem 2rem;
  }
}